import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Helmet from 'react-helmet';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          <meta charSet="utf-8" name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=yes"/>
          <title>`Duànshù(椴樹) Translations`</title>
          <link rel="canonical" href="https://duanshu.me" />                                                                     
        </Helmet>
        <div className="container">
        <Header siteTitle={data.site.siteMetadata.title} />
        <div 
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main className="content">{children}</main>
          <footer className="footer">
            © {new Date().getFullYear()}, Built with
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>

        </div>

      </>
    )}

  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
